<template>
    <div id="app">
        <div id="overlay" v-show="show">
            <img
                style="
                    width: 100%;
                    height: 100%;
                    position: fixed;
                    object-fit: cover;
                    z-index: 999999;
                    top: 0;
                    left: 0;
                "
                src="16:10/img/background/loading-veka_1920x1440.jpg"
            />
            <strong
                style="
                    display: inline-block;
                    position: fixed;
                    z-index: 999999;
                    margin-top: 80px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: #fff;
                    text-align: center;
                "
                >Die Digitale Broschüre kann auf diesem Gerät nicht angezeigt
                werden.</strong
            >
        </div>
        <router-view />
    </div>
</template>

<script>
export default {
    data() {
        return {
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth
        };
    },
    computed: {
        show() {
            //1024 && this.windowHeight >= 768
            if (this.windowWidth >= 768) {
                return false;
            }
            return true;
        }
    },
    methods: {
        onResize() {
            this.windowHeight = window.innerHeight;
            this.windowWidth = window.innerWidth;
        }
    },
    mounted() {
        console.log(this.$utils.screenRatio());
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    }
};
</script>
