<template>
    <div id="video-tabs" style="top: 50px" class="absolute">
        <div class="flex justify-between items-start">
            <div
                class="cursor-pointer mr-12"
                @click="
                    toggle(
                        $utils.screenRatio() +
                            '/video/sequenz-haustuer-sl76_1920x1440.mp4',
                        0
                    )
                "
            >
                <img
                    :src="
                        $utils.screenRatio() +
                            '/img/product/softline76/softline76-logo.png'
                    "
                    alt="SOFTLINE 76"
                    style="max-width: 200px"
                />
                <img
                    v-if="active == 0"
                    :src="$utils.screenRatio() + '/img/icon/blaue-linie.png'"
                    alt=""
                    style="max-width: 200px; margin-top: 15px"
                />
            </div>
            <div
                class="cursor-pointer"
                @click="
                    toggle(
                        $utils.screenRatio() +
                            '/video/sequenz-haustuer-sl82_1920x1440.mp4',
                        1
                    )
                "
            >
                <img
                    :src="
                        $utils.screenRatio() +
                            '/img/product/softline82/softline82-logo.png'
                    "
                    alt="SOFTLINE 82"
                    style="max-width: 200px"
                />
                <img
                    v-if="active == 1"
                    :src="$utils.screenRatio() + '/img/icon/blaue-linie.png'"
                    alt=""
                    style="max-width: 200px; margin-top: 15px"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VideoTabs',
    data() {
        return {
            active: 0,
            video:
                this.$utils.screenRatio() +
                '/video/sequenz-haustuer-sl76_1920x1440.mp4'
        };
    },
    methods: {
        toggle(src, active) {
            this.video = src;
            this.active = active;
            var video = document.getElementById('sequenz-haustueren');
            video.poster = '';
            video.src = src;

            video.load();

            var playPromise = video.play();
            if (playPromise !== undefined) {
                playPromise
                    .then(function() {
                        // Automatic playback started!
                    })
                    .catch(function() {});
            }
        }
    },
    mounted() {
        var that = this;
        document.getElementById('sequenz-haustueren').onended = function() {
            that.toggle(that.video, that.active);
        };
    }
};
</script>

<style></style>
