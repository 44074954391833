<template>
    <div>
        <div class="navigation-container" :class="{ open: this.active }">
            <div id="navigation" class="overflow-y-auto h-screen">
                <a @click="navigate(8)" class="cursor-pointer">
                    <img
                        id="logo-navi"
                        :src="$utils.screenRatio() + '/img/logo/veka-raute.png'"
                    />
                </a>
                <ul>
                    <li><a @click="navigate(14)">VEKA-Qualität</a></li>
                    <li><a @click="navigate(15)">VEKA Systeme</a></li>
                    <li><a @click="navigate(29)">VEKA Oberflächen</a></li>
                    <li><a @click="navigate(32)">VEKA weltweit</a></li>
                    <li><a @click="navigate(33)">VEKA Services</a></li>
                    <li><a @click="navigate(34)">Nachhaltigkeit</a></li>
                    <li><a @click="navigate(35)">Partner werden</a></li>
                </ul>
                <img
                    id="navigation-close"
                    :src="$utils.screenRatio() + '/img/icon/nav-close.png'"
                    @click="toggle()"
                />
                <a
                    href="https://www.veka.de/root/system/impressum.html"
                    target="_blank"
                    class="absolute text-gray-500"
                    style="bottom: 20px"
                    >Impressum</a
                >
            </div>
        </div>
        <img
            id="navigation-arrow"
            :src="$utils.screenRatio() + '/img/icon/nav-open.png'"
            @click="toggle()"
        />
    </div>
</template>

<script>
export default {
    name: 'Navigation',
    data() {
        return {
            active: false
        };
    },
    methods: {
        navigate(triggerID) {
            var trigger = document.querySelectorAll('#trigger-' + triggerID);
            var triggerOffset = trigger[0].offsetTop;
            document.getElementById(
                'scrollable-container'
            ).scrollTop = triggerOffset;
            this.toggle();
        },
        toggle() {
            return (this.active = !this.active);
        }
    }
};
</script>

<style>
.navigation-container {
    position: fixed;
    left: -100%;
    top: 0;
    width: auto;
    height: 100%;
    z-index: 9999;
    background: #ffffff;
}

#navigation {
    /* display: none; */
    background: #ffffff;
    padding-left: 50px;
    padding-right: 40px;
    border-right: 2px;
    /* -webkit-box-shadow: inset -200px 0px 13px -199px rgba(0, 0, 0, 0.75);
                            -moz-box-shadow: inset -200px 0px 13px -199px rgba(0, 0, 0, 0.75);
                            box-shadow: inset -200px 0px 13px -199px rgba(0, 0, 0, 0.75); */
    /* -webkit-animation: slide 0.5s forwards;
                            -webkit-animation-delay: 2s;
                            animation: slide 0.5s forwards;
                            animation-delay: 2s; */
}

#navigation > ul {
    list-style-type: none;
    padding: 0;
}

#navigation ul > li {
    border-bottom: rgba(0, 0, 0, 90) 1px solid;
    padding: 5px 0;
    margin: 5px 0;
    cursor: pointer;
}

#navigation-arrow {
    position: fixed;
    left: 0;
    cursor: pointer;
    max-width: 40px;
    z-index: 99;
    top: 50%;
    transform: translateY(-50%);
}

#navigation-close {
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 99;
    top: 50%;
    transform: translateY(-50%);
}

#navigation img#logo-navi {
    max-width: 100%;
    box-sizing: border-box;
    padding: 25%;
}

.navigation-container.open {
    transition: 1ms all;
    left: 0;
    /* display: block; */
}
</style>
